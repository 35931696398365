export default function config() {
  // Table Handlers
  const tableColumns = [
    { key: 'created_time', label: 'Date' },
    { key: 'auditable_type', label: 'Category' },
    { key: 'event', label: 'Activity' },
    { key: 'text', label: 'Reference' },
  ]

  return {
    tableColumns,
  }
}
