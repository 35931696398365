<template>
  <div class="pb-2">
    <h4 class="text-center font-medium-5">
      <user-name title="" />
    </h4>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :is-print="true"
    >
      <template #cell(created_time)="{ data }">
        {{ data.value }}
      </template>

      <template #cell(auditable_type)="{ data }">
        {{ data.value }}
      </template>

      <template #cell(event)="{ data }">
        {{ data.value }}
      </template>

      <template #cell(text)="{ data }">
        <a
          v-if="isExternalLink(data.item.auditable_for_link)"
          :href="data.item.auditable_for_link"
          class="text-body text-wrap underlined"
          style="text-decoration: underline; cursor: pointer;"
          target="_blank"
        >
          {{ data.value }}
        </a>
        <b-link
          v-else
          :to="renderWithLink(data.item.auditable_for_link)"
          class="text-body text-wrap underlined"
          style="text-decoration: underline"
        >
          {{ data.value }}
        </b-link>
      </template>
    </l-table>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import userMenuItems from '@/views/components/sub-menu/settings-sub-menu/users'
import { BLink } from 'bootstrap-vue'
import tableConfig from '../auditConfig'
import UserName from '../../components/UserName.vue'

export default {
  name: 'AuditList',
  components: {
    LTable,
    UserName,
    BLink,
  },
  data() {
    return {
      error: false,
    }
  },
  computed: {
    audits() {
      return this.$store.state[this.MODULE_NAME].audits
    },
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },

    renderWithLink(data) {
      if (this.isExternalLink(data)) {
        return data
      }
      return `/${data}`
    },
    isExternalLink(link) {
      return link.includes('http://') || link.includes('https://')
    },
  },
  setup() {
    const MODULE_NAME = 'audits'
    const { menuItems } = userMenuItems('users')
    const { tableColumns } = tableConfig()
    return {
      tableColumns,
      MODULE_NAME,
      menuItems,
    }
  },
}
</script>
