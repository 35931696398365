var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('h4',{staticClass:"text-center font-medium-5"},[_c('user-name',{attrs:{"title":""}})],1),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"is-print":true},scopedSlots:_vm._u([{key:"cell(created_time)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(auditable_type)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(event)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(text)",fn:function(ref){
var data = ref.data;
return [(_vm.isExternalLink(data.item.auditable_for_link))?_c('a',{staticClass:"text-body text-wrap underlined",staticStyle:{"text-decoration":"underline","cursor":"pointer"},attrs:{"href":data.item.auditable_for_link,"target":"_blank"}},[_vm._v(" "+_vm._s(data.value)+" ")]):_c('b-link',{staticClass:"text-body text-wrap underlined",staticStyle:{"text-decoration":"underline"},attrs:{"to":_vm.renderWithLink(data.item.auditable_for_link)}},[_vm._v(" "+_vm._s(data.value)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }